const Api = {
  TERMS_AND_CONDITION: "admin/cms/terms-and-condition",
  ADD_EDIT_PRIVACY_POLICY: "admin/cms/add-edit-privacy-policy",
  GET_CMS: "admin/cms",
  ADMIN_LOGIN: "admin/login",
  GET_ALL_BANNER: "admin/get-all-banner",
  BANNER_UPLOAD: "admin/upload-image",
  ABOUTUS: "admin/cms/about-us",
  DELETE_BANNER: "admin/delete-banner",
  GET_REFERRAL_DETAILS: "admin/get-bets",
  GET_INNER_REFERRAL_DETAILS: "admin/get-inner-bets",
  GET_REFERRAL_ALL_RECORDS_DETAILS: "admin/bet-discount-amount",
  ENTER_LOOSE_BETS_PERCENTAGE: "admin/add-edit-percentage",
  GET_LOOSE_BETS_PERCENTAGE: "admin/get-percentage-data",
  ADMIN_TROUGH_AMOUNT_PAID_TO_USER: "admin/amount-paid-to-user",
};
export { Api };
