import React, { useEffect, useState } from "react";
import { getInnerReferralDetails } from "../../../../redux/user/action";
import Index from "../../../Index";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function InnerReferralBetsDetail() {
  const { id } = useParams();

  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [referralRecords, setReferralRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  const [serachedDateRange, setSearchedDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);

  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));

  // when the get referral details then used...
  const handleGetReferralDetails = (values) => {
    setLoading(true);
    dispatch(getInnerReferralDetails({ ...values, walletAddress: id }))
      .then((res) => {
        if (res.status === 200) {
          setReferralRecords(res.data);
          setLoading(false);
          setTotalRecordsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setReferralRecords([]);
          setLoading(false);
          setTotalRecordsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        console.error(error);
        setReferralRecords([]);
        setLoading(false);
        setTotalRecordsCount(0);
        setCurrentPage(1);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    const values = {
      page: 1,
      size: paginationPerPage,
      startDate:
        serachedDateRange?.length > 0 && serachedDateRange[0] !== null
          ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
          : "",
      endDate:
        serachedDateRange?.length > 0 && serachedDateRange[1] !== null
          ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
          : "",
    };
    handleGetReferralDetails(values);
  }, []);

  // when the user change the page then used...
  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        size: paginationPerPage,
        startDate:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        endDate:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetReferralDetails(values);
    },
    [paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // when the user change the pagination per page then used...
  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        size: value,
        startDate:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        endDate:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetReferralDetails(values);
    },
    [serachedDateRange, setPaginationPerPage]
  );

  // when the user change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSelectedDate(data);

      const startOfMonth = dayjs(data).startOf("month").format("YYYY-MM-DD");
      let endOfMonth;
      const selectedMonth = data.month();
      const selectedYear = data.year();
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        endOfMonth = dayjs().format("YYYY-MM-DD"); // Current date if it's the current month
      } else {
        endOfMonth = data.endOf("month").format("YYYY-MM-DD"); // End of the selected month
      }

      setSearchedDateRange([startOfMonth, endOfMonth]);
      if (startOfMonth !== null && endOfMonth !== null) {
        const values = {
          page: 1,
          size: paginationPerPage,
          startDate: startOfMonth !== null ? startOfMonth : "",
          endDate: endOfMonth !== null ? endOfMonth : "",
        };
        handleGetReferralDetails(values);
      }
    },
    [serachedDateRange, paginationPerPage]
  );

  return (
    <>
      <Index.Box className="admin-dashboard-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Inner Referral Detail ({id})
          </Index.Typography>
      
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-userlist-inner-btn-flex">
            <Index.Box className="input-design-div">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                // label="Year and Month"
                minDate={dayjs("2024-01-01")}
                maxDate={dayjs()}
                value={selectedDate}
                onChange={(newValue) => handleChangeDate(newValue)}
                renderInput={(params) => (
                  <Index.TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Index.Box>
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => {
                    navigate(`/admin/referral-details`);
                  }}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <div>
        {/* <Index.Box className="admin-dashboard-content">
          <Index.Box className="admin-user-list-flex admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Inner Referral Details ({id})
            </Index.Typography>
          </Index.Box>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-userlist-inner-btn-flex">
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => {
                    navigate(`/admin/inner-referral-details/${id}`);
                  }}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box> */}

        <Index.Box className="street-bet-common-box">
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Wallet Address
                    </Index.TableCell>
                    <Index.TableCell>Referral Id</Index.TableCell>
                    <Index.TableCell>Winnings</Index.TableCell>
                    <Index.TableCell>Losses</Index.TableCell>
                    <Index.TableCell>Net Losses</Index.TableCell>
                    <Index.TableCell>Reward</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={14}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {referralRecords?.length ? (
                      referralRecords?.map((row, index) => (
                        <Index.TableRow
                          className="cursor-pointer"
                          key={row?.walletAddress}
                        >
                          <Index.TableCell
                            onClick={() => {
                              navigate(
                                `/admin/referral-details/${row?.walletAddress}`,
                                {
                                  state: id,
                                }
                              );
                            }}
                          >
                            {row?.walletAddress ? row?.walletAddress : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.referralCode ? row?.referralCode : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalWinningAmount
                              ? row?.totalWinningAmount
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalLosingAmount
                              ? row?.totalLosingAmount
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalReferralAmount
                              ? row?.totalReferralAmount
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.referralDiscount
                              ? row?.referralDiscount
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) =>
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                      handleChangePaginationPerPage(e)
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(
                      totalRecordsCount > 0
                        ? totalRecordsCount
                        : 1 / paginationPerPage
                    )}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
}
export default InnerReferralBetsDetail;
