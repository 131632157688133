import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useFormik } from "formik";
import * as Yup from "yup";
import PagesIndex from "../../../PagesIndex";
import { ImageURL } from "../../../../config/DataService";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function Banner() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let [allBanner, setAllBanner] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const getAllBanner = () => {
        PagesIndex.DataService.get(PagesIndex.Api.GET_ALL_BANNER).then((res) => {
            if (res?.data?.status == 200) {
                setAllBanner(res?.data?.data);
            }
        });
    };

    useEffect(() => {
        getAllBanner();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleSubmit = async () => {
        if (selectedFiles.length === 0) {
            console.error('No files selected');
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('image', selectedFiles[i]);
        }

        try {
            const response = await PagesIndex.DataService.post(
                PagesIndex.Api.BANNER_UPLOAD,
                formData
            );
            if (response?.data?.status === 201) {
                PagesIndex.toast.success(response?.data?.message);
                getAllBanner();
                handleClose();
            }
        } catch (error) {
            PagesIndex.toast.error(error?.response?.data?.message);
        }

    };

    const handleDeleteBanner = async (deleteId) => {
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", deleteId);
        try {
            const response = await PagesIndex.DataService.post(
                PagesIndex.Api.DELETE_BANNER,
                urlEncoded
            );
            if (response?.data?.status === 200) {
                PagesIndex.toast.success(response?.data?.message);
                getAllBanner();
            }
        } catch (error) {
            PagesIndex.toast.error(error?.response?.data?.message);
        }
    }

    return (
        <>
            <Index.Box className="admin-dashboard-content">
                <Index.Box className="admin-user-list-flex admin-page-title-main">
                    <Index.Typography className="admin-page-title" component="h2" variant="h2">
                        Banner List
                    </Index.Typography>
                    <Index.Box className="admin-userlist-btn-flex">
                        <Index.Box className="admin-userlist-inner-btn-flex">
                            <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                <Index.Button className='admin-adduser-btn btn-primary' onClick={handleOpen}><img src={PagesIndex.Svg.plus} className="admin-plus-icon" alt='plus' />Add Banner</Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            <Index.Box className="grid-column main-dashboard-box">
                <Index.Grid container spacing={3}>
                    <Index.Grid item xs={12} md={12}>
                        <Index.Box className="dashboard-icon-box">
                            <Index.Grid container spacing={3}>
                                {allBanner.map((banner) => {
                                    return (
                                        <Index.Grid item xs={12} md={4}>
                                            <>
                                                <Index.Box className="admin-card-box common-card">
                                                    <Index.Box className="admin-user-card-flex">
                                                        <Index.Box className="admin-card-img-main">
                                                            <img src={ImageURL + banner.image} className='admin-card-img' alt='User' />
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="admin-card-delete-box" >
                                                        <Index.Button type="button" onClick={() => handleDeleteBanner(banner._id)}>
                                                            <img src={PagesIndex.Svg.deleteicon} className='admin-card-delete-icon' alt='Delete' />
                                                        </Index.Button>
                                                    </Index.Box>
                                                </Index.Box>
                                            </>
                                        </Index.Grid>
                                    )
                                })}
                            </Index.Grid>
                        </Index.Box>
                    </Index.Grid>
                </Index.Grid>
            </Index.Box>

            <Index.Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='admin-modal'
            >
                <Index.Box sx={style} className="admin-add-user-modal-inner-main admin-modal-inner">
                    <Index.Box className="admin-modal-header">
                        <Index.Typography id="modal-modal-title" className='admin-modal-title' variant="h6" component="h2">
                            Add Banner Modal
                        </Index.Typography>
                        <Index.Button className='modal-close-btn' onClick={handleClose}><span><img src={PagesIndex.Svg.closeblack} className="admin-modal-close-icon" alt='Close' /></span></Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                        <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className='admin-form-lable'>Banner</Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                                <input
                                    type="file"
                                    multiple
                                    fullWidth
                                    id="fullWidth"
                                    className="admin-form-control"
                                    placeholder=""
                                    // Add the onChange handler here:
                                    onChange={handleFileChange}
                                />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-modal-footer">
                        <Index.Box className="admin-modal-user-btn-flex">
                            <Index.Box className="admin-discard-btn-main border-btn-main">
                                <Index.Button className='admin-discard-user-btn border-btn'>Discard</Index.Button>
                            </Index.Box>
                            <Index.Box className="admin-save-btn-main btn-main-primary">
                                <Index.Button type='submit' className='admin-save-user-btn btn-primary' onClick={(event) => handleSubmit(event.target.files)}>
                                    <img src={PagesIndex.Svg.save} className="admin-user-save-icon" alt='Save'></img>Save</Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </>
    );
}
