import PagesIndex from "../../component/PagesIndex";
import type from "./type";
import { toast } from "react-toastify";

const AdminLoginFetch = (payload) => ({
  type: type.fetchAdminLoginSuccess,
  payload,
});

const AdminLogOutAction = (payload) => ({
  type: type.AdminlogOutSuccess,
  payload,
});

// here through getting all wallets giving referral amount details
export const getReferralDetails = (values) => async () => {
  const response = await PagesIndex.DataService.post(
    PagesIndex.Api.GET_REFERRAL_DETAILS,
    values
  );
  return response.data;
};

// here through inner details sub categoriezed wallet details
export const getInnerReferralDetails = (values) => async () => {
  const response = await PagesIndex.DataService.post(
    PagesIndex.Api.GET_INNER_REFERRAL_DETAILS,
    values
  );
  return response.data;
};

// when the all record for wallet address wise then used...
export const getReferraleAllRecords = (values) => async () => {
  const response = await PagesIndex.DataService.post(
    PagesIndex.Api.GET_REFERRAL_ALL_RECORDS_DETAILS,
    values
  );
  return response.data;
};

// when the enter loose bets percentage then used...
export const enterLoseBetsPercentage = (values) => async () => {
  const response = await PagesIndex.DataService.post(
    PagesIndex.Api.ENTER_LOOSE_BETS_PERCENTAGE,
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

// when the get loose bets percentage then used...
export const getLoseBetsPercentage = (values) => async () => {
  const response = await PagesIndex.DataService.get(
    PagesIndex.Api.GET_LOOSE_BETS_PERCENTAGE,
    values
  );
  return response.data;
};

// when the amount is paid by Admin to User then used...
export const adminThroughAmountPaidToUser = (values) => async () => {
  const response = await PagesIndex.DataService.post(
    PagesIndex.Api.ADMIN_TROUGH_AMOUNT_PAID_TO_USER,
    values
  );
  if (response?.data?.status === 200) {
    toast.success(response.data.message);
    return response.data;
  } else {
    toast.error(response.response.data.message);
  }
};

export { AdminLoginFetch, AdminLogOutAction };
