
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';
import './dashBoard.css';
import './dashBoard.responsive.css';
import { useState } from 'react';



// for custom progressbar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


// for tabal data

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.FailedLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PendingLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
];


export default function Dashboard() {

  const responsive = [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200
      },
      legend: {
        position: 'bottom'
      }
    }
  }]
  const [seriesPie, setSeriesPie] = useState([44, 55, 13, 43])
  const [optionsPie, setOptionsPie] = useState({
    chart: {
      width: 500,
      height: 400,
      type: 'pie',
    },
    labels: ['COMEDY', 'ACTION', 'THRILLER', 'DRAMA'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  })

  const [seriesBar, setSeriesBar] = useState([{
    name: 'Inflation',
    data: [2.3, 3.1, 4.0, 5.1 , 2.3, 3.1, 4.0, 10.1]
  }])
  const [optionsBar, setOptionsBar] = useState(
    {
      chart: {
        height: 300,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ['COMEDY', 'ACTION', 'THRILLER', 'DRAMA','COMEDY', 'ACTION', 'THRILLER', 'DRAMA'],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }

      },

    }
  )
  return (
    <Index.Box className="admin-dashboard-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography className='admin-page-title' component='h2' variant='h2'>Dashboard</Index.Typography>
      </Index.Box>
      <Index.Box className="admin-dashboad-row">
        <Index.Box className="admin-dash-card-row">
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Dashboard</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                  <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon1} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Companies</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>547K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon2} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Subscribed Users</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>12.3K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon3} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Subscribed Users</Index.Typography>
                  <Index.Typography className='admin-dash-price' variant='h1' component='h1'>12.3K</Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                <Index.Box className="admin-dash-icon-box">
                  <img src={PagesIndex.Svg.dashicon3} className="admin-dash-icons" alt='dashboard icon' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="title-row-flex">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title"
            component="h2"
            variant="h2"
          >
            Analytics
          </Index.Typography>
        </Index.Box>
        <Index.Box className="admin-userlist-inner-btn-flex">
          <Index.Box className="admin-adduser-btn-main border-btn-main">
            <Index.Button
              className="admin-adduser-btn border-btn"
            >
              Watch History
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="chart-row-main">
        <Index.Box className="chart-col common-card">
          <Index.Box className="chart-title-main">
            <Index.Typography
              className="chart-title"
              component="h2"
              variant="h2"
            >
              Content Duration Distribution
            </Index.Typography>
          </Index.Box>
          <Index.Box className="pie-chart-main">
            <Index.Box className="chart-box">
              <PagesIndex.ReactApexChart options={optionsPie} series={seriesPie} type="pie" width={optionsPie.chart.width} height={optionsPie.chart.height} />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="chart-col common-card">
          <Index.Box className="chart-title-main">
            <Index.Typography
              className="chart-title"
              component="h2"
              variant="h2"
            >
              Content Duration Distribution
            </Index.Typography>
          </Index.Box>
          <Index.Box className="chart-box">
            <PagesIndex.ReactApexChart options={optionsBar} series={seriesBar} type="bar" />
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* <Index.Box className="admin-progress-bar-content">
          <Index.Typography className='admin-page-title' component='h2' variant='h2'>Progress Bar</Index.Typography>
          <Index.Box className="admin-progress-bar-main">
            <BorderLinearProgress variant="determinate" value={50} className="admin-progress-bar" />
            <span className="admin-progress-bar-text">50%</span>
          </Index.Box>
        </Index.Box> */}
    </Index.Box>
  )
}