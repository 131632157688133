import * as Yup from "yup";

const passwordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const emailRegex = /^(?:(?![=+_])(?:[^\s.=+_]*-?[^\s.=+_]+\.)*[^\s.=+_]*-?[^\s.=+_]+@[^\s.=+_]+\.\w{2,3}(?:\.\w{2,3})?)$/;

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

export const loginSchema = Yup.object().shape({
  email: Yup.string("Enter your email")
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email"),
  password: Yup.string("Enter your password")
    .required("Password is required")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/\d/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
});

export const cmsSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
})