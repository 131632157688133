
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Sidebar from "./admin/defaulLayout/Sidebar";
import Header from "./admin/defaulLayout/Header";
import PaidLable from "./common/lables/PaidLable";
import FailedLable from "./common/lables/FailedLable";
import PendingLable from "./common/lables/PendingLable";
import PrimaryButton from './common/Button/PrimaryButton';
import BorderButton from './common/Button/BorderButton';
import AuthFooter from "./admin/defaulLayout/AuthFooter";
import AuthBackground from "./admin/defaulLayout/AuthBackground";
import { useLocation, useNavigate } from "react-router-dom";
import EditProfile from './admin/pages/account/editProfile/EditProfile';
import ChangePassword from './admin/pages/account/changePassword/ChangePassword';
import DataNotFound from './common/dataNotFound/DataNotFound';
import Loader from './common/loader/Loader';
import PageLoader from './common/loader/PageLoader';
import Spinner from './common/spinner/Spinner';
import DataService from '../config/DataService';
import {Api} from '../config/Api';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';


export default {
      Svg,
      Png,
      Sidebar,
      Header,
      PaidLable,
      FailedLable,
      PendingLable,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
      useLocation,
      useNavigate,
      BorderButton,
      EditProfile,
      ChangePassword,
      DataNotFound,
      PageLoader,
      Loader,
      Spinner,
      DataService,
      Api,
      ViewCarouselOutlinedIcon,
      DevicesOtherOutlinedIcon,
      DisplaySettingsOutlinedIcon,
      InfoOutlinedIcon,
      PrivacyTipOutlinedIcon,
      FactCheckOutlinedIcon,
};
