
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Video from "../assets/Video";
import Sidebar from '../component/admin/defaulLayout/Sidebar';
import Header from '../component/admin/defaulLayout/Header';
import PaidLable from '../component/common/lables/PaidLable';
import FailedLable from '../component/common/lables/FailedLable';
import PendingLable from '../component/common/lables/PendingLable';
import PrimaryButton from '../component/common/Button/PrimaryButton';
import BorderButton from '../component/common/Button/BorderButton';
import AuthBackground from '../component/admin/defaulLayout/AuthBackground';
import AuthFooter from '../component/admin/defaulLayout/AuthFooter';
import EditProfile from '../component/admin/pages/account/editProfile/EditProfile';
import ChangePassword from '../component/admin/pages/account/changePassword/ChangePassword';
import ReactApexChart from "react-apexcharts";
import DataNotFound from '../component/common/dataNotFound/DataNotFound';
import UserHeader from '../component/user/defaultLayout/UserHeader';
import UserFooter from '../component/user/defaultLayout/UserFooter';
import DataService from '../config/DataService';
import {Api} from '../config/Api';
import { toast,ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
export default {
      Svg,
      Png,
      Video,
      Sidebar,
      Header,
      PaidLable,
      FailedLable,
      PendingLable,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
      BorderButton,
      EditProfile,
      ChangePassword,
      DataNotFound,
      UserHeader,
      UserFooter,
      ReactApexChart,
      DataService,
      Api,  
      toast,
      ToastContainer,
      useSelector,
      useLocation
};
