import { SwipeableDrawer } from '@mui/material';
import React from 'react'
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';
import './userList.css';
import './userList.responsive.css';

// for modal design 

const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
};


// for table data


function createData(name, address, city, number, stutus, action) {
      return { name, address, city, number, stutus, action };
}

const rows = [
      // createData(<Index.Box className="admin-table-data-flex"><img src={PagesIndex.Png.userlist1} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>, <Index.Box className="admin-table-data-flex"><Index.Typography className='admin-table-data-text'>12974 Keebler Prairie, South Brendon, Id, Cl</Index.Typography></Index.Box>, 'New Kaelachester', '623-880-0509 X6880', 'Status', <Index.Box className="admin-table-data-btn-flex "><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.blueedit} className='admin-icon' alt='Edit'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.trash} className='admin-icon' alt='Trash'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.yelloweye} className='admin-icon' alt='View'></img></Index.Button></Index.Box>),
];



export default function UserList() {



      const [age, setAge] = React.useState('');

      const handleChangedropdown = (event) => {
            setAge(event.target.value);
      };


      // add user modal
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);


      // delete modal
      const [openDelete, setOpenDelete] = React.useState(false);
      const handleOpenDelete = () => setOpenDelete(true);
      const handleCloseDelete = () => setOpenDelete(false);



      // filter
      const [state, setState] = React.useState({
            top: false,
            left: false,
            bottom: false,
            right: false,
      });

      const toggleDrawer = (anchor, open) => (event) => {
            if (
                  event &&
                  event.type === 'keydown' &&
                  (event.key === 'Tab' || event.key === 'Shift')
            ) {
                  return;
            }

            setState({ ...state, [anchor]: open });
      };

      const list = (anchor) => (
            <Index.Box className="filter-main"
                  role="presentation"
                  onClick={toggleDrawer(anchor, false)}
                  onKeyDown={toggleDrawer(anchor, false)}
            >
            </Index.Box>
      );


      return (
            <>
                  <Index.Box className="admin-dashboard-content admin-user-list-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                              <Index.Typography className='admin-page-title admin-user-list-page-title' component='h2' variant='h2'>User List</Index.Typography>
                              <Index.Box className="admin-userlist-btn-flex">
                                    <Index.Box className="admin-search-main">
                                          <Index.Box className="admin-search-box">
                                                <Index.Box className="admin-form-group">
                                                      <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="admin-form-control"
                                                            placeholder="Search user"
                                                      />
                                                      <img
                                                            src={PagesIndex.Svg.search}
                                                            className="admin-search-grey-img admin-icon" alt='search'
                                                      ></img>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-filter-main">
                                          {['right'].map((anchor) => (
                                                <React.Fragment key={anchor}>
                                                      <Index.Box className="admin-export-btn-main border-btn-main">
                                                            <Index.Button className='admin-export-btn border-btn' onClick={toggleDrawer(anchor, true)}><img src={PagesIndex.Svg.filter} className="admin-down-icon admin-icon" alt='download icon' />Filter</Index.Button>
                                                      </Index.Box>
                                                      <SwipeableDrawer
                                                            className='filter-main'
                                                            anchor={anchor}
                                                            open={state[anchor]}
                                                            onClose={toggleDrawer(anchor, false)}
                                                            onOpen={toggleDrawer(anchor, true)}
                                                      >
                                                            <Index.Box className="admin-filter-header">
                                                                  <Index.Typography className='admin-filter-title' >User Filter</Index.Typography>
                                                                  <span onClick={toggleDrawer(anchor, false)}><img src={PagesIndex.Png.close} className="admin-filter-close-icon" alt='Close' /></span>
                                                            </Index.Box>
                                                            <Index.Box className="admin-filter-inner-main">
                                                                  <Index.Box className="admin-input-box admin-filter-input-box">
                                                                        <Index.FormHelperText className='admin-form-lable'>Name</Index.FormHelperText>
                                                                        <Index.Box className="admin-form-group">
                                                                              <Index.TextField
                                                                                    fullWidth
                                                                                    id="fullWidth"
                                                                                    className="admin-form-control"
                                                                                    placeholder=""
                                                                              />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box className="admin-input-box admin-filter-input-box">
                                                                        <Index.FormHelperText className='admin-form-lable'>City</Index.FormHelperText>
                                                                        <Index.Box className="admin-form-group">
                                                                              <Index.TextField
                                                                                    fullWidth
                                                                                    id="fullWidth"
                                                                                    className="admin-form-control"
                                                                                    placeholder=""
                                                                              />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box className="admin-input-box admin-filter-input-box">
                                                                        <Index.FormHelperText className='admin-form-lable'>Status</Index.FormHelperText>
                                                                        <Index.Box className='admin-checkbox-main admin-filter-checkbox-main admin-input-box'>
                                                                              <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Active" className='admin-checkbox-lable' />
                                                                              <Index.FormControlLabel control={<Index.Checkbox />} label="Deactivate" className='admin-checkbox-lable' />
                                                                              <Index.FormControlLabel control={<Index.Checkbox />} label="Pending" className='admin-checkbox-lable' />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className="admin-filter-footer">
                                                                  <Index.Box className="admin-filter-btn-main border-btn-main btn-main-primary">
                                                                        <Index.Button className="border-btn admin-filter-cancel-btn">Cancel</Index.Button>
                                                                        <Index.Button className="btn-primary admin-filter-btn">Filter</Index.Button>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </SwipeableDrawer>
                                                </React.Fragment>
                                          ))}
                                    </Index.Box>
                                    <Index.Box className="admin-userlist-inner-btn-flex">
                                          <Index.Box className="admin-export-btn-main border-btn-main">
                                                <Index.Button className='admin-export-btn border-btn' onClick={handleOpenDelete}><img src={PagesIndex.Svg.down} className="admin-down-icon admin-icon" alt='download' />Export</Index.Button>
                                          </Index.Box>
                                          <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                                <Index.Button className='admin-adduser-btn btn-primary' onClick={handleOpen}><img src={PagesIndex.Svg.plus} className="admin-plus-icon" alt='plus' />Add User</Index.Button>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="card-border common-card">
                              <Index.Box className="admin-userlist-table-main page-table-main">
                                    <Index.TableContainer component={Index.Paper} className='table-container'>
                                          <Index.Table aria-label="simple table" className='table'>
                                                <Index.TableHead className='table-head'>
                                                      <Index.TableRow className='table-row'>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">NAME</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">ADDRESS</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">CITY</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">NUMBER</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">STATUS</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">ACTION</Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className='table-body'>
                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PagesIndex.Png.userlist1} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><Index.Typography className='admin-table-data-text'>  12974 Keebler Prairie, South Brendon, Id, Cl</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-btn-flex">
                                                                        <Index.Tooltip
                                                                              title="Edit"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn'>
                                                                                    <img src={PagesIndex.Svg.blueedit} className='admin-icon' alt='Edit' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                        
                                                                        <Index.Tooltip
                                                                              title="View"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn'>
                                                                                    <img src={PagesIndex.Svg.yelloweye} className='admin-icon' alt='View' /></Index.Button>
                                                                        </Index.Tooltip>

                                                                        <Index.Tooltip
                                                                              title="Delete"
                                                                              arrow
                                                                              placement="bottom"
                                                                              className="admin-tooltip"
                                                                        >
                                                                              <Index.Button className='admin-table-data-btn'>
                                                                                    <img src={PagesIndex.Svg.trash} className='admin-icon' alt='Trash' />
                                                                              </Index.Button>
                                                                        </Index.Tooltip>
                                                                  </Index.Box>
                                                            </Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableBody>
                                          </Index.Table>
                                    </Index.TableContainer>
                              </Index.Box>
                              <Index.Box className="admin-pagination-main">
                                    <Index.Pagination count={3} variant="outlined" shape="rounded" className='admin-pagination' />
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>


                  <Index.Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='admin-modal'
                  >
                        <Index.Box sx={style} className="admin-add-user-modal-inner-main admin-modal-inner">
                              <Index.Box className="admin-modal-header">
                                    <Index.Typography id="modal-modal-title" className='admin-modal-title' variant="h6" component="h2">
                                          Add User Modal
                                    </Index.Typography>
                                    <Index.Button className='modal-close-btn' onClick={handleClose}><span><img src={PagesIndex.Svg.closeblack} className="admin-modal-close-icon" alt='Close' /></span></Index.Button>
                              </Index.Box>
                              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                          <Index.FormHelperText className='admin-form-lable'>First Name</Index.FormHelperText>
                                          <Index.Box className="admin-form-group">
                                                <Index.TextField
                                                      fullWidth
                                                      id="fullWidth"
                                                      className="admin-form-control"
                                                      placeholder=""
                                                />
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                          <Index.FormHelperText className='admin-form-lable'>Language</Index.FormHelperText>
                                          <Index.Box className='admin-dropdown-box'>
                                                <Index.FormControl className='admin-form-control admin-drop-form-control'>
                                                      <Index.Select className='admin-dropdown-select admin-drop-select'
                                                            value={age}
                                                            onChange={handleChangedropdown}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                      >
                                                            <Index.MenuItem value="" className='admin-drop-menuitem'>
                                                                  English
                                                            </Index.MenuItem>
                                                            <Index.MenuItem value={10} className='admin-drop-menuitem'>English</Index.MenuItem>
                                                            <Index.MenuItem value={20} className='admin-drop-menuitem'>English</Index.MenuItem>
                                                            <Index.MenuItem value={30} className='admin-drop-menuitem'>English</Index.MenuItem>
                                                      </Index.Select>
                                                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='down arrow'></img></span> */}
                                                </Index.FormControl>
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                          <Index.FormHelperText className='admin-form-lable'>Last Name</Index.FormHelperText>
                                          <Index.Box className="admin-form-group">
                                                <Index.TextField
                                                      fullWidth
                                                      id="fullWidth"
                                                      className="admin-form-control"
                                                      placeholder=""
                                                />
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                          <Index.FormHelperText className='admin-form-lable'>Email<span className='admin-span-text admin-common-para'>(Optional)</span></Index.FormHelperText>
                                          <Index.Box className="admin-form-group">
                                                <Index.TextField
                                                      fullWidth
                                                      id="fullWidth"
                                                      className="admin-form-control"
                                                      placeholder=""
                                                />
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                          <Index.FormHelperText className='admin-form-lable'>Date</Index.FormHelperText>
                                          <Index.Box className="admin-form-group">
                                                <Index.TextField
                                                      type='date'
                                                      fullWidth
                                                      id="fullWidth"
                                                      className="admin-form-control"
                                                      placeholder=""
                                                />
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                              <Index.Box className="admin-modal-footer">
                                    <Index.Box className="admin-modal-user-btn-flex">
                                          <Index.Box className="admin-discard-btn-main border-btn-main">
                                                <Index.Button className='admin-discard-user-btn border-btn'>Discard</Index.Button>
                                          </Index.Box>
                                          <Index.Box className="admin-save-btn-main btn-main-primary">
                                                <Index.Button className='admin-save-user-btn btn-primary'><img src={PagesIndex.Svg.save} className="admin-user-save-icon" alt='Save'></img>Save</Index.Button>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Modal>



                  <Index.Modal
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='admin-modal'
                  >
                        <Index.Box sx={style} className="admin-delete-modal-inner-main admin-modal-inner">
                              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                                    <Index.Box className="admin-modal-circle-main">
                                          <img src={PagesIndex.Svg.closecircle} className="admin-user-circle-img" alt='Close' />
                                    </Index.Box>
                                    <Index.Typography className='admin-delete-modal-title' component="h2" variant='h2'>Are you sure?</Index.Typography>
                                    <Index.Typography className='admin-delete-modal-para admin-common-para' component="p" variant='p'>Do you really want to delete these records? This process cannot be undone.</Index.Typography>
                                    <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                                          <Index.Button className='admin-modal-cancel-btn border-btn' onClick={handleCloseDelete}>Cancel</Index.Button>
                                          <Index.Button className='admin-modal-delete-btn btn'>Delete</Index.Button>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Modal>
            </>
      )
}
