
import { styled, alpha } from '@mui/material/styles';
import React, { useEffect, useState } from 'react'
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';
import './defaultLayout.css'
import './defaultLayout.responsive.css'


export default function Header(props) {

  // for page redirect
  const navigate = PagesIndex.useNavigate()

  // for open in header profile menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      marginTop: 38,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        '0px 3px 6px #00000029',
      '& .MuiMenu-list': {
        padding: '0px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const handleLogout = async () => {
    localStorage.removeItem('token');
  }

  return (
    <>
      <Index.Box className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"}`}>
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img src={PagesIndex.Png.adminlogo} className="admin-sidebar-logo" alt='logo' />
            <Index.Button className='admin-bergur-button' onClick={() => {
              props.setOpen(!props.open)
              document.body.classList[props.open ? "remove" : "add"](
                "admin-body-overflow"
              );
            }}>
              <img src={PagesIndex.Svg.bergurmenu} className="bergurmenu-icon" alt='dashboard logo'></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className='admin-header-right'>
            {/* <Index.Button className='admin-header-icon-box'>
              <img src={PagesIndex.Svg.notification} className="admin-header-icon" alt='dashboard logo'></img>
            </Index.Button>
            <Index.Button className='admin-header-icon-box'>
              <img src={PagesIndex.Svg.settings} className="admin-header-icon" alt='dashboard bell icon'></img>
            </Index.Button> */}
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className='admin-drop-header-btn'
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Index.Box className="admin-flex-drop-main">
                  <img src={PagesIndex.Png.usericon} className="admin-header-profile-icon" alt='dashboard bell icon'></img>
                  <Index.Box className="title-admin-drop">
                    <Index.Typography variant="h5" component="h5" className='admin-header-drop'>Admin</Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className='admin-drop-header-menu'
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {/* <Index.MenuItem className='admin-drop-header-menuitem' onClick={() => {
                navigate("/admin/account");
                handleClose();
              }}> <img src={PagesIndex.Svg.profilegrey} className="admin-drop-header-icon admin-icon" alt='Profile' /> Profile</Index.MenuItem> */}

              {/* <Index.MenuItem className='admin-drop-header-menuitem' onClick={() => {
                navigate("/admin/account");
                handleClose();
              }}> <img src={PagesIndex.Svg.settings} className="admin-drop-header-icon admin-icon" alt='Setting' /> Account Settings</Index.MenuItem> */}

              <Index.MenuItem className='admin-drop-header-menuitem' onClick={() => {
                handleLogout()
                navigate("/");
                handleClose();
              }}> <img src={PagesIndex.Svg.logout} className="admin-drop-header-icon admin-icon" alt='Logout' /> Sign Out</Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
