import React, { useEffect, useState } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import "./defaultLayout.css";
import "./defaultLayout.responsive.css";

export default function Sidebar(props) {
  // for page redirect
  const location = PagesIndex.useLocation();

  // for submenu dropdown
  const [open, setOpen] = React.useState(false);
  const handleClickAccount = () => {
    setOpen(!open);
  };

  // for mobile menu sidebar
  useEffect(() => {
    if (window.innerWidth < 786) {
      props.setOpen(false);
    } else {
      props.setOpen(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 786) {
        props.setOpen(false);
      } else {
        props.setOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 786) {
      document.body.classList[props.open ? "add" : "remove"](
        "admin-body-overflow"
      );
    }
  }, [props.open]);

  return (
    <Index.Box
      className={`admin-sidebar-main ${props.open ? "active" : "deactive"}`}
    >
      <Index.Box className="admin-sidebar-inner-main">
        <Index.Link className="admin-sidebar-logo-main">
          {/* <img
            src={PagesIndex.Svg.adminLogo}
            className="admin-sidebar-logo"
            alt="logo"
          /> */}
          <img
            src={
              props.open
                ? PagesIndex.Svg.adminLogo
                : PagesIndex.Png.adminLogoMini
            } // Change logo based on the open state
            className="admin-sidebar-logo"
            alt="logo"
          />
          <Index.Button
            onClick={() => {
              props.setOpen(!props.open);
            }}
            className="admin-sidebar-close-btn"
          >
            <img
              src={PagesIndex.Svg.close}
              className="admin-close-icon"
              alt="logo"
            />
          </Index.Button>
        </Index.Link>

        <Index.Box className="admin-sidebar-list-main scrollbar">
          <Index.List className="admin-sidebar-list">
            <Index.ListItem className="admin-sidebar-listitem">
              {props.open ? (
                <Index.Link
                  to="/admin/banner"
                  onClick={() => {
                    if (window.innerWidth < 786) {
                      props.setOpen(!props.open);
                    }
                  }}
                  className={
                    location?.pathname.includes("/admin/banner")
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <PagesIndex.ViewCarouselOutlinedIcon />
                  <span className="admin-sidebar-link-text">Banner</span>
                </Index.Link>
              ) : (
                <Index.Tooltip
                  title="Banner"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.Link
                    to="/admin/banner"
                    onClick={() => {
                      if (window.innerWidth < 786) {
                        props.setOpen(!props.open);
                      }
                    }}
                    className={
                      location?.pathname.includes("/admin/banner")
                        ? "admin-sidebar-link active"
                        : "admin-sidebar-link"
                    }
                  >
                    <PagesIndex.ViewCarouselOutlinedIcon />
                    <span className="admin-sidebar-link-text">Banner</span>
                  </Index.Link>
                </Index.Tooltip>
              )}
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              {props.open ? (
                <Index.Link
                  to="/admin/referral-details"
                  onClick={() => {
                    if (window.innerWidth < 786) {
                      props.setOpen(!props.open);
                    }
                  }}
                  className={
                    location?.pathname.includes("/admin/referral-details")
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <PagesIndex.DevicesOtherOutlinedIcon />
                  <span className="admin-sidebar-link-text">Referral</span>
                </Index.Link>
              ) : (
                <Index.Tooltip
                  title="Referral"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.Link
                    to="/admin/referral-details"
                    onClick={() => {
                      if (window.innerWidth < 786) {
                        props.setOpen(!props.open);
                      }
                    }}
                    className={
                      location?.pathname.includes("/admin/referral-details")
                        ? "admin-sidebar-link active"
                        : "admin-sidebar-link"
                    }
                  >
                    <PagesIndex.DevicesOtherOutlinedIcon />
                    <span className="admin-sidebar-link-text">Referral</span>
                  </Index.Link>
                </Index.Tooltip>
              )}
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              {props.open ? (
                <Index.Link
                  to="/admin/bets-percentage-details"
                  onClick={() => {
                    if (window.innerWidth < 786) {
                      props.setOpen(!props.open);
                    }
                  }}
                  className={
                    location?.pathname.includes(
                      "/admin/bets-percentage-details"
                    )
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <PagesIndex.DevicesOtherOutlinedIcon />
                  <span className="admin-sidebar-link-text">
                    Bets Percentage
                  </span>
                </Index.Link>
              ) : (
                <Index.Tooltip
                  title="Bets Los Management"
                  arrow
                  placement="right"
                  className="admin-tooltip"
                >
                  <Index.Link
                    to="/admin/bets-percentage-details"
                    onClick={() => {
                      if (window.innerWidth < 786) {
                        props.setOpen(!props.open);
                      }
                    }}
                    className={
                      location?.pathname.includes(
                        "/admin/bets-percentage-details"
                      )
                        ? "admin-sidebar-link active"
                        : "admin-sidebar-link"
                    }
                  >
                    <PagesIndex.DevicesOtherOutlinedIcon />
                    <span className="admin-sidebar-link-text">
                      Bets Percentage
                    </span>
                  </Index.Link>
                </Index.Tooltip>
              )}
            </Index.ListItem>

            <Index.ListItem
              className="admin-sidebar-listitem admin-submenu-listitem-main"
              onClick={handleClickAccount}
            >
              <Index.Box className="admin-submenu-link-box">
                {props.open ? (
                  <>
                    <Index.Link className="admin-sidebar-link">
                      <PagesIndex.DisplaySettingsOutlinedIcon />
                      <span className="admin-sidebar-link-text">CMS</span>
                    </Index.Link>
                    {open ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                    <Index.Box className="admin-submenu-main">
                      <Index.Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className="admin-submenu-collapse"
                      >
                        <Index.List
                          component="div"
                          disablePadding
                          className="admin-sidebar-submenulist"
                        >
                          <Index.ListItem
                            className="admin-sidebar-listitem"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Index.Link
                              to="/admin/about-us"
                              onClick={() => {
                                if (window.innerWidth < 786) {
                                  props.setOpen(!props.open);
                                }
                              }}
                              className={
                                location?.pathname.includes("/admin/about-us")
                                  ? "admin-sidebar-link active"
                                  : "admin-sidebar-link"
                              }
                            >
                              <PagesIndex.InfoOutlinedIcon />
                              <span className="admin-sidebar-link-text">
                                About Us
                              </span>
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem
                            className="admin-sidebar-listitem"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Index.Link
                              to="/admin/terms-and-condition"
                              onClick={() => {
                                if (window.innerWidth < 786) {
                                  props.setOpen(!props.open);
                                }
                              }}
                              className={
                                location?.pathname.includes(
                                  "/admin/terms-and-condition"
                                )
                                  ? "admin-sidebar-link active"
                                  : "admin-sidebar-link"
                              }
                            >
                              <PagesIndex.FactCheckOutlinedIcon />
                              <span className="admin-sidebar-link-text">
                                Terms & Conditions
                              </span>
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem
                            className="admin-sidebar-listitem"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Index.Link
                              to="/admin/privacy-policy"
                              onClick={() => {
                                if (window.innerWidth < 786) {
                                  props.setOpen(!props.open);
                                }
                              }}
                              className={
                                location?.pathname.includes(
                                  "/admin/privacy-policy"
                                )
                                  ? "admin-sidebar-link active"
                                  : "admin-sidebar-link"
                              }
                            >
                              <PagesIndex.PrivacyTipOutlinedIcon />
                              <span className="admin-sidebar-link-text">
                                Privacy Policy
                              </span>
                            </Index.Link>
                          </Index.ListItem>
                        </Index.List>
                      </Index.Collapse>
                    </Index.Box>
                  </>
                ) : (
                  <Index.Tooltip
                    title="CMS"
                    arrow
                    placement="right"
                    className="admin-tooltip"
                  >
                    <Index.Link className="admin-sidebar-link">
                      <PagesIndex.DisplaySettingsOutlinedIcon />
                      <span className="admin-sidebar-link-text">CMS</span>
                    </Index.Link>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.ListItem>
          </Index.List>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
