import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";

//#start region Admin
import Login from "../component/admin/auth/login/Login";
import ForgotPassword from "../component/admin/auth/forgotPassword/ForgotPassword";
import Otp from "../component/admin/auth/otp/Otp";
import SignIn from "../component/admin/auth/signIn/SignIn";
import AdminLayOut from "../container/admin/pages/adminLayout/AdminLayOut";
import UserList from "../container/admin/pages/userList/UserList";
import DashBoard from "../container/admin/pages/dashBoard/DashBoard";
import AddUser from "../container/admin/pages/addUser/AddUser";
import UserCard from "../container/admin/pages/userCard/UserCard";
import AccountLayout from "../container/admin/pages/accountLayout/AccountLayout";
import PrivacyPolicy from "../container/admin/pages/privacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../container/admin/pages/termsAndCondition/TermsAndCondition";
import Banner from "../container/admin/pages/banner/banner";
//#endregion

//#start region user
import UserLayout from "../container/user/pages/userLayout/UserLayout";
import Home from "../container/user/pages/home/Home";
import DataNotFound from "../component/common/dataNotFound/DataNotFound";
import Loader from "../component/common/loader/Loader";
import Spinner from "../component/common/spinner/Spinner";
import PageLoader from "../component/common/loader/PageLoader";
import UserLogin from "../container/user/auth/UserLogin";
import UnderMaintenance from "../component/common/underMaintenance/UnderMaintenance";
import PageNotFound from "../component/common/pageNotFound/PageNotFound";
import AboutUs from "../container/admin/pages/aboutUs/AboutUs";
import ReferralBets from "../container/admin/pages/ReferralBets/ReferralBets";
import ReferralDetail from "../container/admin/pages/ReferralBets/ReferralDetail";
import BetsLoose from "../container/admin/pages/BetsLoose/BetsLoose";
import InnerReferralBetsDetail from "../container/admin/pages/ReferralBets/InnerReferralBetsDetail";
import BetsPercentageDetails from "../container/admin/pages/BetsPercentage/BetsPercentageDetails";

const history = createBrowserHistory();
export default function Routes() {
  const login = useSelector((state) => {
    return state.AdminReducer.isAdminLogin;
  });
  // const login = true;

  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp-verify" element={<Otp />} />
        <Route path="/sign-in" element={<SignIn />} />

        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<AdminLayOut />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="account" element={<AccountLayout />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="user-card" element={<UserCard />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-condition" element={<TermsAndCondition />} />
            <Route path="data-not-found" element={<DataNotFound />} />
            <Route path="loader" element={<Loader />} />
            <Route path="spinner" element={<Spinner />} />
            <Route path="page-loader" element={<PageLoader />} />
            <Route path="banner" element={<Banner />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="referral-details" element={<ReferralBets />} />
            <Route
              path="inner-referral-details/:id"
              element={<InnerReferralBetsDetail />}
            />
            <Route path="referral-details/:id" element={<ReferralDetail />} />
            <Route
              path="bets-percentage-details"
              element={<BetsPercentageDetails />}
            />
            {/* <Route path="bets-loose-details" element={<BetsLoose />} /> */}
          </Route>
        </Route>
        {/* start user routes */}

        <Route path="login" element={<UserLogin />} />
        <Route path="under-maintenance" element={<UnderMaintenance />} />
        <Route path="page-not-found" element={<PageNotFound />} />
        <Route path="/user" element={<UserLayout />}>
          <Route path="home" element={<Home />} />
        </Route>

        {/* end user routes */}
      </Routess>
    </BrowserRouter>
  );
}
