import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Index from "../../../Index";
import { getReferralDetails } from "../../../../redux/user/action";
import dayjs from "dayjs";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function ReferralDetail() {
  const { id } = useParams();
  let { state } = useLocation();
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [referralBetsWalletWise, setReferralBetsWalletWise] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);

  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));

  //   when the get the referral wise wallets details then used...
  const handleGetReferralDetailWalletWise = (values) => {
    setLoading(true);
    dispatch(getReferralDetails({ walletAddress: id, ...values }))
      .then((res) => {
        if (res.status === 200) {
          setReferralBetsWalletWise(res.data);
          setLoading(false);
          setTotalRecordsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setReferralBetsWalletWise([]);
          setLoading(false);
          setTotalRecordsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        console.error(error);
        setReferralBetsWalletWise([]);
        setLoading(false);
        setTotalRecordsCount(0);
        setCurrentPage(1);
      });
  };

  //   when the page first time load then used...
  useEffect(() => {
    const values = {
      page: 1,
      size: paginationPerPage,
      startDate:
        serachedDateRange?.length > 0 && serachedDateRange[0] !== null
          ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
          : "",
      endDate:
        serachedDateRange?.length > 0 && serachedDateRange[1] !== null
          ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
          : "",
    };
    handleGetReferralDetailWalletWise(values);
  }, []);

  // when the user change the page then used...
  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        size: paginationPerPage,
        startDate:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        endDate:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetReferralDetailWalletWise(values);
    },
    [paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // when the user change the pagination per page then used...
  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        size: value,
        startDate:
          serachedDateRange?.length > 0 && serachedDateRange[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        endDate:
          serachedDateRange?.length > 0 && serachedDateRange[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
      };
      handleGetReferralDetailWalletWise(values);
    },
    [serachedDateRange, setPaginationPerPage]
  );

  // when the user change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSelectedDate(data);

      const startOfMonth = dayjs(data).startOf("month").format("YYYY-MM-DD");
      let endOfMonth;
      const selectedMonth = data.month();
      const selectedYear = data.year();
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        endOfMonth = dayjs().format("YYYY-MM-DD"); // Current date if it's the current month
      } else {
        endOfMonth = data.endOf("month").format("YYYY-MM-DD"); // End of the selected month
      }
      setSearchedDateRange([startOfMonth, endOfMonth]);
      if (startOfMonth !== null && endOfMonth !== null) {
        const values = {
          page: 1,
          size: paginationPerPage,
          startDate: startOfMonth !== null ? startOfMonth : "",
          endDate: endOfMonth !== null ? endOfMonth : "",
        };
        handleGetReferralDetailWalletWise(values);
      }
    },
    [serachedDateRange, paginationPerPage]
  );
  return (
    <>
      <Index.Box className="admin-dashboard-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Referral Detail ({id})
          </Index.Typography>
       
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-userlist-inner-btn-flex">
            <Index.Box className="input-design-div">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                // label="Year and Month"
                minDate={dayjs("2024-01-01")}
                maxDate={dayjs()}
                value={selectedDate}
                onChange={(newValue) => handleChangeDate(newValue)}
                renderInput={(params) => (
                  <Index.TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Index.Box>
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => {
                    navigate(`/admin/inner-referral-details/${state}`);
                  }}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <div>
        <Index.Box className="street-bet-common-box">
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Wallet Address
                    </Index.TableCell>
                    <Index.TableCell>Game</Index.TableCell>
                    <Index.TableCell>Amount</Index.TableCell>
                    <Index.TableCell>Possible Win</Index.TableCell>
                    <Index.TableCell>Bet Date</Index.TableCell>
                    <Index.TableCell>Is Lost</Index.TableCell>
                    <Index.TableCell>Resolve Date</Index.TableCell>
                    <Index.TableCell>Is Payment</Index.TableCell>
                    <Index.TableCell>Transfered Amount</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={14}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {referralBetsWalletWise?.length ? (
                      referralBetsWalletWise?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.walletAddress ? row?.walletAddress : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.bets?.outcomes?.length
                              ? row?.bets?.outcomes?.map((outComeData) => (
                                  <>
                                    <li>{outComeData?.game?.title}</li>
                                  </>
                                ))
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>{row?.bets?.amount}</Index.TableCell>
                          <Index.TableCell>
                            {row?.bets?.possibleWin
                              ? row?.bets?.possibleWin
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.bets?.createdAt
                              ? dayjs(+row?.bets?.createdAt * 1000).format(
                                  "DD.MM.YYYY, hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.bets?.isLose ? "Yes" : "No"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.createdAt
                              ? moment(row?.createdAt).format(
                                  "DD.MM.YYYY, hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.isPayments ? "Yes" : "No"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.bets?.amount ? row?.bets?.amount * 0.1 : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) =>
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                      handleChangePaginationPerPage(e)
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                  {console.log("totalRecordsCount", totalRecordsCount)}
                  {console.log("paginationPerPage", paginationPerPage)}
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(
                      (totalRecordsCount > 0 ? totalRecordsCount : 1) /
                        paginationPerPage
                    )}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
}
export default ReferralDetail;
