import React from "react";
import { styled } from '@mui/material/styles';
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import './auth.css';


// for custom checkbox deauth


const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  border: "1px solid #ffffffa6",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'block',
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%238c116d'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export default function UserLogin() {

  // for password eye hide and show

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Index.Box className="user-auth-main">
          <video muted autoPlay='true' loop id="1111" class="auth-video"  >
    {/* <source src={PagesIndex.Video.authVideo} type="video/mp4" alt="auth video" /> */}
  </video> 
      <Index.Box className="user-auth-card-main">
        <Index.Typography className="user-auth-title">Sign In</Index.Typography>
        <Index.Typography
          component="p"
          variant="p"
          className="user-auth-para"
        >
          Please enter your credentials to sign in!
        </Index.Typography>
        <Index.Box className="user-input-box">
          <Index.FormHelperText className="user-form-lable">
            User Name
          </Index.FormHelperText>
          <Index.Box className="user-form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="user-form-control"
              placeholder="user@mealon.com"
              autocomplete="off"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="user-input-box">
          <Index.FormHelperText className="user-form-lable">
            Password
          </Index.FormHelperText>
          <Index.Box className="user-form-group">
            <Index.OutlinedInput
              className="user-form-control user-form-control-eye"
              autocomplete="off"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <Index.InputAdornment position="end">
                  <Index.IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Index.VisibilityOff />
                    ) : (
                      <Index.Visibility />
                    )}
                  </Index.IconButton>
                </Index.InputAdornment>
              }
            />
             {/* <Index.FormHelperText error>
            Password
          </Index.FormHelperText> */}
          </Index.Box>
        </Index.Box>
        <Index.Box className="user-flex-all user-forgot-row">
          <Index.Box className="user-checkbox-main">
            <BpCheckbox />
            <Index.Typography className="user-checkbox-lable">Remember Me</Index.Typography>
          </Index.Box>
          <Index.Link className="user-text-decoration-none user-forgot-para">
            Forgot Password?
          </Index.Link>
        </Index.Box>

        <Index.Box className="btn-main-primary user-login-btn-main">
          <Index.Button className="btn-primary user-login-btn">
            Sign In
          </Index.Button>
        </Index.Box>
        <Index.Box className="user-condition-content">
          <Index.Typography className="user-condition-text"> Don't have an account? <span className="user-condition-span"><Index.Link className="user-condition-span">Sign Up here</Index.Link></span></Index.Typography>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
