import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useFormik } from "formik";
import PagesIndex from "../../../PagesIndex";
import { cmsSchema } from "../../../../validation/FormikValidation";

export default function AboutUs() {
    const [aboutUs, setAboutUs] = useState({
        title: "",
        description: "",
    });

    // get Cms data
    const getCmsList = () => {
        PagesIndex.DataService.get(PagesIndex.Api.GET_CMS).then((res) => {
            if (res?.data?.status == 200) {
                setAboutUs({
                    title: res?.data?.data?.aboutUs?.title ?? "",
                    description: res?.data?.data?.aboutUs?.description ?? "",
                });
            }
        });
    };
    useEffect(() => {
        getCmsList();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {

    const urlEncoded = new URLSearchParams();
    urlEncoded.append("title", values?.title);
    urlEncoded.append("description", values?.description);
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.ABOUTUS,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message);
        getCmsList();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
    };

    const formik = useFormik({
        initialValues: aboutUs,
        validationSchema: cmsSchema,
        enableReinitialize:true,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <Index.Box className="admin-dashboard-content">
                <Index.Box className="admin-user-list-flex admin-page-title-main">
                    <Index.Typography
                        className="admin-page-title"
                        component="h2"
                        variant="h2"
                    >
                        About Us
                    </Index.Typography>
                </Index.Box>
                <form onSubmit={formik.handleSubmit}>
                    <Index.Box className="cms-page-content-main privacy-policy-page common-card">
                        <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                                Title
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="admin-form-control"
                                    placeholder="Title"
                                    type="text"
                                    name="title"
                                    value={formik.values?.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Index.Box>
                            {formik.touched.title && formik.errors.title && (
                                <Index.FormHelperText error>
                                    {formik.errors.title}
                                </Index.FormHelperText>
                            )}
                        </Index.Box>

                        <Index.Box className="admin-input-box admin-modal-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                                Description
                            </Index.FormHelperText>
                            <Index.Stack component="form">
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "blockQuote",
                                            "|",
                                            "numberedList",
                                            "bulletedList",
                                            "|",
                                            "undo",
                                            "redo",
                                            "link",
                                            "insertTable",
                                            //   "imageUpload",
                                            //   "mediaEmbed",
                                        ],
                                        placeholder: "Enter Text...",
                                    }}
                                    data={formik.values?.description}
                                    onChange={(event, editor) => {
                                        formik.setFieldValue("description", editor.getData());
                                    }}
                                />
                            </Index.Stack>
                            {formik.touched.description && formik.errors.description && (
                                <Index.FormHelperText error>
                                    {formik.errors.description}
                                </Index.FormHelperText>
                            )}
                        </Index.Box>

                        <Index.Box className="admin-save-btn-main btn-main-primary">
                            <Index.Button
                                type="submit"
                                className="admin-save-user-btn btn-primary"
                                disabled={formik.isSubmitting}
                            >
                                Save
                            </Index.Button>
                        </Index.Box>
                    </Index.Box>
                </form>
            </Index.Box>
        </>
    );
}
