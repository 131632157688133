import React from "react";
import { Helmet } from "react-helmet";

const DynamicTitle = ({ title = "Barge Management" }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default DynamicTitle;
