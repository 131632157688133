import usericon from './images/png/user.png';
import edit from './images/png/edit.png';
import close from './images/png/close.png';
import maintenanceimg from './images/png/maintenance.png'
import pagenotfoundimg from './images/png/page-not-found-img.png'
import adminLogoMini from './images/png/adminLogoMini.png'

const Png = {
      usericon,
      edit,
      close,
      maintenanceimg,
      pagenotfoundimg,
      adminLogoMini,
}

export default Png;