import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import {
  enterLoseBetsPercentage,
  getLoseBetsPercentage,
} from "../../../../redux/user/action";

function BetsPercentageDetails() {
  const dispatch = Index.useDispatch();
  const [percentageAmount, setPercetageAmount] = useState(0);

  // when the get loose bets percentage then used...
  const handleGetBargePercentage = () => {
    dispatch(getLoseBetsPercentage())
      .then((res) => {
        if (res?.status === 200) {
          setPercetageAmount(res?.data?.percentage);
        } else {
          setPercetageAmount(0);
        }
      })
      .catch((error) => {
        console.error(error);
        setPercetageAmount(0);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetBargePercentage();
  }, []);

  // when the user submit the data then used...
  const handleSubmitForm = useCallback(() => {
    const values = {
      percentage: percentageAmount,
    };
    dispatch(enterLoseBetsPercentage(values))
      .then((res) => {
        if (res.status === 200) {
          setPercetageAmount(res.percentage);
        } else {
          setPercetageAmount(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [percentageAmount]);

  return (
    <>
      <div>
        <Index.Box className="admin-dashboard-content">
          <Index.Box className="admin-user-list-flex admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Bets Percentage Details
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="street-bet-common-box">
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-input-box admin-modal-input-box">
              <Index.FormHelperText className="admin-form-lable">
                Percentage
              </Index.FormHelperText>
              <Index.Box className="admin-form-group">
                <Index.TextField
                  type="number"
                  value={percentageAmount}
                  onChange={(e) => {
                    setPercetageAmount(e.target.value);
                  }}
                />
              </Index.Box>
            </Index.Box>
            <Index.Box>
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Submit
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
}
export default BetsPercentageDetails;
