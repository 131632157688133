import axios from 'axios';
// const API_ENDPOINT = "http://localhost:2020/api/";

const getBaseUrl = () => {
  if (process.env.REACT_APP_ENV === "PRODUCTION") {
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_LIVE,
      imageURL: process.env.REACT_APP_IMAGE_URL_LIVE
    };
  } else if (process.env.REACT_APP_ENV === "APPWORKDEMO") {
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_APPWORKDEMO,
      imageURL: process.env.REACT_APP_IMAGE_URL_APPWORKDEMO
    };
  } else {
    // Default to local server
    return {
      baseURL: process.env.REACT_APP_API_BASE_URL_LOCAL,
      imageURL: process.env.REACT_APP_IMAGE_URL_LOCAL
    };
  }
};

const { baseURL, imageURL } = getBaseUrl();
export const ImageURL = imageURL;

const DataService = axios.create({
  baseURL: baseURL,
});

DataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return error;
  }
);

export default DataService;