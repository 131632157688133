import React from "react";
import { Navigate, Outlet,  useLocation, } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const isLoggedIn = localStorage.getItem('token');
    return isLoggedIn ? (
        <Outlet />
    ) : (
        <Navigate to="/" state={{ from: location }} replace={true} />
    );
};

export default PrivateRoute;